<template>
  <div>
    <!-- begin::Entry -->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid py-8">
        <!--begin::Education-->
        <div class="d-flex flex-row">
          <UserNav activePage="startlivestream" activeTab="4"></UserNav>
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">

            <div>
              <!--begin::Section-->
              <div>
                <loading :active.sync="visible" :can-cancel="true">
                  <div class="loader__container">
                    <img class="w100 h100" src="/media/logos/ingomu-preloader.gif" />
                  </div>
                </loading>
                <!--begin::Card-->
                <div class="card card-custom gutter-b">
                  <div class="card-body">
                    <div class="d-flex">
                      <!--begin: Pic-->
                      <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                        <div class="symbol symbol-50 symbol-lg-120">
                          <img alt="Pic" :src="photo" />
                        </div>
                        <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none" v-if="courseInfo.length > 0">
                          <span class="font-size-h3 symbol-label font-weight-boldest">{{ courseInfo[0].coach.first
                          }}</span>
                        </div>
                      </div>
                      <!--end: Pic-->
                      <!--begin: Info-->
                      <div class="flex-grow-1">
                        <!--begin: Title-->
                        <div class="d-flex align-items-center justify-content-between flex-wrap">
                          <div class="mr-3">
                            <!--begin::Name-->
                            <a href="javascript:void(0)"
                              class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                              v-if="courseInfo.length > 0">
                              {{ courseInfo[0].coach.first }}</a>
                            <!--end::Name-->
                            <!--begin::Contacts-->
                            <div class="d-flex flex-wrap my-2">
                              <a href="javascript:void(0)"
                                class="text-dark text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                                v-if="courseInfo.length > 0">
                                <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                  <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->

                                  <!--end::Svg Icon-->
                                </span>{{ courseInfo[0].title }}</a>
                            </div>
                            <!--end::Contacts-->
                          </div>
                          <div class="my-lg-0 my-1">
                            <button :class="{
                              'btn btn-danger': !livestreamActive,
                              'btn btn-success': livestreamActive,
                            }" class="btn-xs font-weight-bold mr-2 my-1">
                              {{ statusText }}
                            </button>
                            <button class="btn btn-xs font-weight-bold mr-2 my-1" :class="{
                              'btn btn-danger': !checked,
                              'btn btn-success': checked,
                            }">
                              <b-form-checkbox v-model="checked" name="check-button" switch size="md"
                                :disabled="checkedDisabled">
                              </b-form-checkbox>
                            </button>
                          </div>
                        </div>
                        <!--end: Title-->
                        <!--begin: Content-->
                        <div class="d-flex align-items-center flex-wrap justify-content-between">
                          <div class="flex-grow-1 col-md-6 font-weight-bold text-dark py-5 py-lg-2 mr-5 pl-0">To
                            generate your
                            Live Ingest
                            Server and Live Stream Key you must
                            first toggle on the session by clicking the red toggle switch
                            to the right, then you can copy and paste the keys in Streamlabs OBS.<br /><br />
                            Some browser extensions may cause issues. We recommend that
                            browser extensions are turned off to use this service. For more
                            information check out the
                            <a href="https://www.alphr.com/how-to-disable-extensions-in-chrome/" class="font-weight-bold"
                              target="_blank">documentation</a>.
                          </div>
                          <div class="d-flex flex-wrap align-items-center py-2">
                            <div v-show="ingestServerCred != '' && streamKeyCred != ''">
                              <div class="text-right">
                                <button class="btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold"
                                  @click="copyText('ingest')">Copy Live
                                  Ingest Server</button>
                                <div class="font-weight-bold mb-2">
                                  <input class="font-weight-bold text-dark width420 text-right mt-2" readonly
                                    type="text" v-model="ingestServerCred" />
                                  <input type="hidden" id="ingestInput" :value="ingestServerCred" />
                                </div>
                              </div>
                              <div class="mt-5 text-right">
                                <button class="btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold"
                                  @click="copyText('stream')">Copy Live
                                  Stream Key</button>
                                <div class="font-weight-bold mb-2">
                                  <input type="hidden" id="streamInput" :value="streamKeyCred" />
                                  <input class="font-weight-bold text-dark width420 text-right mt-2" readonly
                                    v-model="streamKeyCred" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--end: Content-->
                      </div>
                      <!--end: Info-->
                    </div>
                    <div data-v-42be1e73="" class="separator separator-solid my-7" v-if="sessionId != null"></div>
                    <div class="col-xxl-12" v-if="sessionId != null">
                      <!--begin::Forms Widget 2-->
                      <div class="card card-custom gutter-b">
                        <!--begin::Header-->
                        <div class="card-header border-0 pt-5 pb-4 mh-0">
                          <h3 class="card-title align-items-start flex-column">
                            <span class="card-label font-weight-bolder text-dark fs-15">Session Description</span>
                          </h3>
                        </div>
                        <!--begin::Body-->
                        <div class="card-body py-0 pb-5">
                          <!--begin::Top-->
                          <div>
                            <p class="text-dark font-size-lg font-weight-normal">
                              {{ sessionTitle }}
                            </p>
                          </div>
                          <!--end::Top-->
                          <form class="position-relative mt-5">
                            <label>Characters Remaining:
                              {{
                                detailForm.maxChars - detailForm.sessionDescription.length
                              }}</label>
                            <div class="input-group">
                              <input type="text" :maxlength="detailForm.maxChars" v-model="detailForm.sessionDescription"
                                class="form-control" placeholder="Update Session Description" />
                              <div class="input-group-append">
                                <button class="btn btn-secondary" type="button" @click="addSessionDetail">
                                  Update
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-solid my-7"></div>
                    <!--begin: Items-->
                    <div class="d-flex align-items-center flex-wrap">
                      <!--begin: Item-->
                      <div class="d-flex align-items-center mr-20 my-1">
                        <span class="mr-4">
                          <i class="flaticon-folder-1 icon-2x text-dark font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark">
                          <span class="font-weight-bolder font-size-sm">Slots Available</span>
                          <span class="font-weight-bolder font-size-h5">
                            {{ recordingCount }}
                          </span>
                        </div>
                      </div>
                      <!--end: Item-->
                      <!--begin: Item-->
                      <div class="d-flex align-items-center mr-20 my-1">
                        <span class="mr-4">
                          <i class="flaticon-black icon-2x text-dark font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark">
                          <span class="font-weight-bolder font-size-sm">Likes</span>
                          <span class="font-weight-bolder font-size-h5">
                            {{ likeCount }}
                          </span>
                        </div>
                      </div>
                      <!--end: Item-->
                      <!--begin: Item-->
                      <div class="d-flex align-items-center mr-20 my-1">
                        <span class="mr-4">
                          <i class="flaticon-speech-bubble icon-2x text-dark font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark">
                          <span class="font-weight-bolder font-size-sm">Chat Messages</span>
                          <span class="font-weight-bolder font-size-h5">
                            {{ sessionChats.length }}
                          </span>
                        </div>
                      </div>
                      <!--end: Item-->
                      <!--begin: Item-->
                      <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                        <span class="mr-4">
                          <i class="flaticon-avatar icon-2x text-dark font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark">
                          <span class="font-weight-bolder font-size-sm">Members</span>
                          <span class="font-weight-bolder font-size-h5">
                            {{ onlineUsers.length }}
                          </span>
                        </div>
                      </div>
                      <!--end: Item-->
                      <!--begin: Item-->
                      <div class="d-flex my-1">
                        <div>
                          <flip-countdown class="w350 float-right" :deadline="deadline" :showDays="false"
                            @timeElapsed="timeElapsedHandler" countdownSize="30px" labelSize="10px"></flip-countdown>
                        </div>
                      </div>
                      <!--end: Item-->
                    </div>
                    <!--begin: Items-->
                  </div>
                </div>
                <!--end::Card-->
                <div class="row col-lg-12">
                  <!-- <div class="col-lg-8">

                    <div class="row">
                    </div>
                  </div> -->
                  <div class="col-lg-4">
                        <!--begin::Advance Table Widget 3-->
                        <div class="card card-custom card-stretch gutter-b">
                          <!--begin::Body-->
                          <div class="card-body pt-2 pb-0 mt-n3">
                            <div class="tab-content mt-5" id="myTabTables9">
                              <b-container fluid class="p-0">
                                <!-- User Interface controls -->
                                <b-row class="mb-10">
                                  <b-col lg="12" class="my-1">
                                    <b-form-group class="mb-0">
                                      <b-input-group size="sm">
                                        <b-form-input id="filter-input" v-model="filter" type="search"
                                          placeholder="Type to Search">
                                        </b-form-input>

                                        <b-input-group-append>
                                          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <b-table id="latest-sessions-table" :fields="fields" :items="onlineUsers" :filter="filter"
                                  show-empty thead-class="hidden_header" :per-page="perPage" :current-page="currentPage"
                                  class="table table-borderless table-vertical-center">
                                  <template #cell(image)="data">
                                    <div class="symbol symbol-50 symbol-light mr-1">
                                      <span class="symbol symbol-35">
                                        <img :src="imageSource(data.item.user.profileimage)" alt="" />
                                      </span>
                                    </div>
                                  </template>
                                  <template #cell(name)="data">
                                    <div class="text-dark
                                font-weight-bolder
                                text-hover-primary
                                mb-1
                                font-size-lg">
                                      {{ data.item.user.firstname }}
                                    </div>
                                  </template>
                                  <template #cell(status)="data">
                                    <button @click="changeMutedStatus(data.item.user, false)"
                                      v-if="data.item.isMuted == true"
                                      class="label label-lg label-inline label-light-ingomugreen mr-2">Press to
                                      unmute</button>
                                    <button @click="changeMutedStatus(data.item.user, true)" v-else
                                      class="label label-lg label-inline label-light-danger mr-2">Press to mute</button>
                                  </template>
                                </b-table>
                              </b-container>
                            </div>
                          </div>
                          <!--end::Body-->
                          <!-- begin::footer -->
                          <div class="card-footer">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
                            </b-pagination>
                          </div>
                          <!-- end::footer-->
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <!--begin::Charts Widget 3-->
                        <div class="card card-custom card-stretch gutter-b">
                          <!--begin::Body-->
                          <div class="card-body">
                            <!--begin::Container-->
                            <div>
                              <!--begin::Body-->
                              <div id="scrollChatContainer" ref="scrollChatContainer" class="h-360">
                                <!--begin::Item-->
                                <div class="d-flex py-5" v-for="chat in sessionChats" :key="chat.user.datetime">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-square symbol-50 mr-3">
                                    <span class="symbol symbol-50">
                                      <img :src="imageSource(chat.user.profileimage)" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Info-->
                                  <div class="d-flex flex-column flex-row-fluid">
                                    <!--begin::Info-->
                                    <div class="d-flex align-items-center flex-wrap">
                                      <a href="javascript:void(0)"
                                        class="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6">{{
                                          chat.user.firstname
                                        }}</a>
                                    </div>
                                    <span class="text-dark font-size-lg font-weight-normal pt-1">{{ chat.message
                                    }}</span>
                                    <!--end::Info-->
                                  </div>
                                  <!--end::Info-->
                                </div>
                                <!--end::Item-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Container-->
                            <!--begin::Separator-->
                            <div class="separator separator-solid mt-5 mb-4"></div>
                            <!--end::Separator-->
                            <!--begin::Editor-->
                            <form class="position-relative">
                              <textarea class="form-control border-0 p-0 pr-10 resize-none w-80" rows="2"
                                placeholder="Type a message" v-model="messageForm.message"></textarea>
                              <div class="
                      position-absolute top-0 right-0 mt-n1 mr-n2
                    ">
                                <div class="mr-3"></div>
                                <div>
                                  <button type="button" class="
                          btn btn-primary btn-md
                          text-uppercase
                          font-weight-bold
                          chat-send
                          py-2
                          px-6
                        " @click="addMessage()">
                                    Send
                                  </button>
                                </div>
                              </div>
                            </form>
                            <!--edit::Editor-->
                          </div>
                          <!--end::Body-->

                        </div>

                      </div>

                  <div class="col-lg-4">
                    <div class="">
                      <!--begin::Body-->
                      <div class="">
                        <div>
                          <div id="videocontainer">
                            <div class="smartphone">
                              <div class="content w-100 h-100 p-0">
                                <div v-show="isLivestreamActive == true">
                                  <video ref="videoPlayer" class="video-js"></video>
                                  <div class="widget--profile widget--profile-video">
                                    <img class="widget--profile__image_small" :src="photo" alt="">
                                    <div class="live-box">Live</div>
                                  </div>
                                </div>
                                <div v-show="isLivestreamActive == false"
                                  class="no-livestream w-100 h-100 overflow-hidden">
                                  <div class="no-livestream-img">
                                    <div class="text-center">
                                      <img class="widget--profile__image_medium" :src="photo" alt="">
                                      <br /><br />
                                      <p class="offline-text">Livestream is offline</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Section-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { registerIVSTech } from "amazon-ivs-player";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";
import PerfectScrollbar from "perfect-scrollbar";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import UserNav from "@/view/layout/header/UserNav.vue";

const fmt = "YYYY-MM-DD HH:mm:ss";

export default {
  components: {
    FlipCountdown,
    Loading: VueLoading,
    UserNav
  },
  mixins: [defaultProfileImageMixin],
  data() {
    return {
      visible: false,
      checked: false,
      checkedText: "Off",
      checkedDisabled: false,
      statusText: "Offline",
      showRefresh: false,
      timeout: null,
      deadline: moment().format(fmt),
      ingestServerCred: "",
      streamKeyCred: "",
      showButtonText: "Show",
      player: null,
      videoSource: "",
      videoOptions: {
        autoplay: true,
        muted: true,
        controls: false,
        techOrder: ["AmazonIVS"],
        height: "300",
        aspectRatio: "16:9",
        responsive: true,
        fluid: true,
      },
      messageForm: {
        message: "",
      },
      learnerArrow: true,
      learnerShow: false,
      chatArrow: true,
      chatShow: false,
      credsHidden: true,
      likeThrobShow: false,
      sessionTitle: "",
      detailForm: {
        sessionDescription: "",
        maxChars: 200,
      },
      default_photo: "media/users/blank.png",
      current_photo: null,
      current_name: "",
      current_title: "",
      perPage: 3,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "image", label: "" },
        // { key: "name", label: "" },
        { key: "name", label: "" },
        { key: "status", label: "" },
      ],
      isLivestreamActive: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", function (e) {
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "Did you switch off the recording?";
    });
  },
  beforeDestroy() {
    // when navigating to other components
    this.$store.dispatch("gcfDeleteIVSChannel");
    this.$store.commit("RESET_SESSIONID");
    this.$store.commit("RESET_LIKECOUNT");
  },
  beforeUnmount() {
    // Destroy the player instance
    if (this.player) {
      this.player.dispose();
    }
  },
  componentDidMount() {
    const rightScroll = new PerfectScrollbar("#scrollChatContainer");
    const leftScroll = new PerfectScrollbar("#scrollLearnerContainer");
  },
  mounted() {
    this.$store.dispatch("getCoachLivestreamInfo");
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 3 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 6 });

    registerIVSTech(videojs, {
      wasmWorker: this.createAbsolutePath(
        "/assets/amazon-ivs-wasmworker.min.js"
      ),
      wasmBinary: this.createAbsolutePath(
        "/assets/amazon-ivs-wasmworker.min.wasm"
      ),
    });

    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);

    const maintanenceBlock = async () => {
      // console.log("start maintanence block")
      try {
        const listRecording = await this.$store.dispatch(
          "listRecordingConfigurations"
        );
        // console.log(listRecording);
        // await this.$store.dispatch("deleteIVSChannel", { deleteAll: true });
        // await this.$store.dispatch("deleteRecordingConfiguration", {
        //   deleteAll: true,
        // });
        const listChannels = await this.$store.dispatch("listIVSChannels");
        // console.log(listChannels);
      } catch (e) {
        // console.log(e);
      }
    };
    // maintanenceBlock();

    this.$store.dispatch("liveUpateRecordingConfigurations");
  },
  methods: {
    open() {
      const routeData = this.$router.resolve({
        name: "troubleshooting"
      });
      window.open(routeData.href, "_blank");
    },
    changeMutedStatus(user, status) {
      if (this.coachListId !== null && this.sessionId !== null) {
        this.$store.dispatch("changeMutedStatus", {
          sessionid: this.sessionId,
          coach_list_id: this.coachListId,
          isMuted: status,
          firstname: user.firstname,
          userid: user.user_id
        });
      }
    },
    addSessionDetail() {
      if (this.detailForm.sessionDescription == "") {
        Swal.fire({
          title: "",
          text: "You must enter a session description!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
        });
      } else {
        const newDescription = this.detailForm.sessionDescription;
        this.$store.dispatch("addSessionDetail", {
          title: newDescription,
          sessionId: this.sessionId,
        });
        this.sessionTitle = newDescription;
        this.detailForm.sessionDescription = "";
      }
    },
    turnOffLearners() {
      this.learnerArrow = false;
    },
    turnOffChat() {
      this.chatArrow = false;
    },
    createAbsolutePath(assetPath) {
      return new URL(assetPath, document.URL).toString();
    },
    copyText(copyType) {
      if (!this.credsHidden) {
        let testingCodeToCopy;
        if (copyType === "ingest") {
          testingCodeToCopy = document.querySelector("#ingestInput");
        } else if (copyType === "stream") {
          testingCodeToCopy = document.querySelector("#streamInput");
        }
        testingCodeToCopy.setAttribute("type", "text");
        testingCodeToCopy.select();

        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "successful" : "unsuccessful";
          Swal.fire({
            title: "",
            text: "The code was copied!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
          });
        } catch (err) {
          Swal.fire({
            title: "",
            text: "Unable to copy!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
          });
        }

        /* unselect the range */
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      } else {
        Swal.fire({
          title: "Warning!",
          text: "Please generate your credentials first!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
        });
      }
    },
    timeElapsedHandler: function () {
      this.turnOff();
    },
    turnOff() {
      this.chatShow = false;
      this.learnerShow = false;
      this.checkedText = "Off";
      this.checked = false;
      this.$store.dispatch("deleteIVSChannel");
      clearTimeout(this.timeout);
      this.showRefresh = false;
      this.deadline = moment().format(fmt);
      this.$store.commit("RESET_LIVESTREAMACTIVE");
      this.credsHidden = true;
    },
    noSlotsAvailable() {
      this.checkedText = "Off";
      this.checked = false;
      this.deadline = moment().format(fmt);
      Swal.fire({
        title: "",
        text: "There was a problem generating credentials. Refresh the page and try again.",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: true,
      });
    },
    addMessage() {
      if (this.messageForm.message === "") {
        Swal.fire({
          title: "",
          text: "You must enter a message!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
        });
      } else {
        if (this.coachListId !== null && this.sessionId !== null) {
          const newmessage = this.messageForm.message;
          this.$store.dispatch("addNewMessage", {
            sessionid: this.sessionId,
            coach_list_id: this.coachListId,
            text: newmessage,
          });
        }
        this.messageForm.message = "";
      }
    },
    updateScroll() {
      this.$nextTick(
        () =>
        (this.$refs.scrollChatContainer.scrollTop =
          this.$refs.scrollChatContainer.scrollHeight)
      );
    },
  },
  watch: {
    courseInfo(newValue) {
      this.current_photo = newValue[0].coach.image;
      this.current_name = newValue[0].coach.first;
      this.current_title = newValue[0].title;
    },
    checked(value) {
      if (value) {
        this.visible = true;
        const runBlock = async () => {
          try {
            const channels = await this.$store.dispatch("listIVSChannels");
            const recordings = await this.$store.dispatch(
              "listRecordingConfigurations"
            );
            if (channels < 200 && recordings < 20) {
              this.checkedText = "On";
              this.$store
                .dispatch("createRecordingConfiguration")
                .then((res) => {
                  this.$store.dispatch("checkRecordingArn").catch((error) => {
                    this.noSlotsAvailable();
                    this.visible = false;
                  });
                })
                .catch((error) => {
                  this.noSlotsAvailable();
                  this.visible = false;
                });
            } else {
              this.noSlotsAvailable();
              this.visible = false;
            }
          } catch (e) {
            this.noSlotsAvailable();
            this.visible = false;
          }
        };
        runBlock();
      } else {
        this.turnOff();
        this.checkedDisabled = true;
        this.visible = false;
        this.statusText = "Offline";
      }
    },
    recordingActive(newValue, oldValue) {
      if (newValue === "ACTIVE") {
        this.$store.dispatch("createIVSChannel").then(
          (resolve) => {
            this.credsHidden = false;
            this.$store.dispatch("getIVSChannelInfo");
          },
          (error) => {
            this.noSlotsAvailable();
            this.visible = false;
          }
        );
      }
    },
    ingestServer(newValue, oldValue) {
      this.ingestServerCred = newValue;
      this.visible = false;
    },
    streamKey(newValue, oldValue) {
      this.streamKeyCred = newValue;
      this.visible = false;
    },
    playbackUrl(newValue, oldValue) {
      if (newValue !== null) {
        this.videoSource = newValue;
      } else {
        this.player.reset();
      }
    },
    livestreamActive(newValue, oldValue) {
      if (newValue == "LIVE") {
        this.deadline = moment().add(45, "m").format(fmt);
        this.statusText = "Online";
        this.$store.dispatch("getSessionId");
        this.isLivestreamActive = true
        this.player.src({
          src: this.videoSource,
          type: "application/x-mpegURL"
        });
      } else {
        this.isLivestreamActive = false
      }
    },
    channelTimeout(newValue, oldValue) {
      if (newValue) {
        this.turnOff();
        this.checkedDisabled = true;
        this.visible = false;
        this.statusText = "Offline";
        this.isLivestreamActive = false
      }
    },
    sessionId(newValue, oldValue) {
      if (newValue) {
        this.$store.dispatch("getOnlineUsers", {
          coachListId: this.coachListId,
          sessionId: newValue,
        });
        this.$store.dispatch("getSessionChats", {
          coachListId: this.coachListId,
          sessionId: newValue,
        });
        this.$store.dispatch("getLikeCounts", {
          coachListId: this.coachListId,
          sessionId: newValue,
        });
      }
    },
    onlineUsers(value) {
      if (value.length > 0) {
        this.learnerShow = true;
      }
    },
    sessionChats(value) {
      this.updateScroll();
      if (value.length > 0) {
        this.chatShow = true;
      }
    },
    likeCount() {
      this.likeThrobShow = true;
      this.timer = setInterval(() => {
        this.likeThrobShow = false;
      }, 3000);
    },
  },
  computed: {
    ...mapGetters([
      "ingestServer",
      "playbackUrl",
      "streamKey",
      "recordingActive",
      "livestreamActive",
      "recordingCount",
      "channelTimeout",
      "onlineUsers",
      "sessionChats",
      "sessionId",
      "coachListId",
      "likeCount",
      "courseInfo"
    ]),
    checkboxTextColor: function () {
      return this.checkedDisabled ? "text-disabled" : "text-dark";
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    rows() {
      return this.onlineUsers.length;
    }
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm('Do you really want to leave? Are you live streaming?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
};
</script>

<style scoped>
.width420 {
  width: 420px;
}

.flip-card {
  font-size: 2rem !important;
}

#scrollChatContainer {
  position: relative;
  height: 350px;
  overflow: auto;
}

#scrollLearnerContainer {
  position: relative;
  min-height: 200px;
  max-height: 450px;
  overflow: auto;
}

.w350 {
  width: 350px;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}

.h-360 {
  height: 360px;
}

.w-80 {
  width: 80%;
}

.video-js,
.vjs-tech {
  position: absolute !important;
  height: 100% !important;
  border-radius: none !important;
}

.widget--profile__image_small {
  margin-right: 15px;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.widget--profile__image_medium {
  margin-right: 15px;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.widget--profile-video {
  position: absolute;
  top: 15px;
  left: 30px;
  text-align: center;
  display: flex;
}

.live-box {
  background-color: #3b5998;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  border-radius: 15%;
}

.no-livestream {
  background-color: #3b5998;
}

.no-livestream-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.offline-text {
  color: #ffffff;
  font-size: 16x;
}


/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 600px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 3px solid red;
  background: black;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>
